import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Client } from "../../types";
import { toast } from "react-toastify";
import { ClientForm } from "./form";
import { useApi } from "api";

export const ClientAdd = () => {
  const navigate = useNavigate();
  const api = useApi();
  const params = useParams<{ agencyId: string; advisorId: string }>();
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: async (values: Omit<Client, "id" | "fullName" | "agency">) => {
      const response = await api.post<Client>("/api/v1/clients/", {
        ...values,
        agent: params.advisorId,
      });
      return response.data;
    },
    onSuccess: (data: Client) => {
      toast.success("Client created");
      queryClient.invalidateQueries({
        queryKey: [
          "agency",
          params.agencyId,
          "advisor",
          params.advisorId,
          "client",
        ],
      });
      navigate(
        `/agency/${params.agencyId}/advisor/${params.advisorId}/client/${data.id}`,
      );
    },
    onError: () => {
      toast.error("Error creating client");
    },
  });

  return (
    <div className="w-full rounded-md border border-gray-300 p-2">
      <div>
        <span className="text-4xl text-teal-400">New Client</span>{" "}
        <span className="text-gray-400">Client</span>
      </div>
      <ClientForm
        initialValues={{
          agent: params.advisorId,
          agentId: params.advisorId || "",
          agencyId: params.agencyId || "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          postal: "",
          filingStatus: undefined,
          currentAge: undefined,
          gender: undefined,
          hasSpouse: false,
          spouseFirstName: "",
          spouseLastName: "",
          spouseAge: undefined,
          spouseGender: undefined,
          spouseEmail: "",
          retirementAge: 65,
          retirementGoals: [],
          assetIncomeSources: [],
        }}
        mutateFunction={(values) => {
          create.mutate(values);
        }}
        goBack={() =>
          navigate(`/agency/${params.agencyId}/advisor/${params.advisorId}`)
        }
      />
      <br />
    </div>
  );
};
