import { useMemo } from "react";
import { Client } from "types";
import numeral from "numeral";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ClientDetailColumnProps {
  client: Client;
}
export const ClientDetailColumn: React.FC<ClientDetailColumnProps> = (
  props,
) => {
  const { client } = props;

  const monthlyIncome = client.assetIncomeSources
    .map((incomeSource) => incomeSource.monthlyIncome)
    .reduce((a, b) => a + b, 0);

  const currentTaxRate =
    (client.federalTaxRateCurrent ??
      0 + (client.stateLocalTaxRateCurrent ?? 0)) / 100;
  const retirementTaxRate =
    (client.federalTaxRateRetirement ??
      0 + (client.stateLocalTaxRateCurrent ?? 0)) / 100;

  return useMemo(
    () => (
      <div className="flex justify-between">
        <div className="w-80">
          <div className="flex justify-between">
            <span className="text-sm">{client.fullName}</span>
            <span className="text-right text-sm">
              {client.gender === 1 ? "Male" : "Female"}, {client.currentAge}
            </span>
          </div>
          {client.hasSpouse && (
            <div className="flex justify-between">
              <span className="text-sm">{client.spouseFullName}</span>
              <span className="text-right text-sm">
                {client.spouseGender === 1 ? "Male" : "Female"},{" "}
                {client.spouseAge}
              </span>
            </div>
          )}
        </div>
        <div className="flex-row justify-between">
          <div className="flex-col justify-start text-sm">
            Monthly Income{" "}
          </div>
          <div className="text-right text-sm">
            {numeral(monthlyIncome || 0).format("$0,0.00")}
          </div>
        </div>
        <div className="flex-row justify-between">
          <div className="flex-col justify-start text-sm">
            Current Tax Rate{" "}
          </div>
          <div className="text-right text-sm">
            {numeral(currentTaxRate || 0).format("0.000%")}
          </div>
        </div>
        <div className="flex-row justify-between">
          <div className="flex-col justify-start text-sm">
            Retirement Tax Rate{" "}
          </div>
          <div className="text-right text-sm">
            {numeral(retirementTaxRate || 0).format("0.000%")}
          </div>
        </div>
      </div>
    ),
    [client, monthlyIncome, currentTaxRate, retirementTaxRate],
  );
};
