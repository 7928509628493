import { useQuery } from '@tanstack/react-query';
import { useParams, Link } from 'react-router-dom';
import { BucketList } from './bucket_list';
import { Client } from '../../types';
import { Spin } from 'components';
import { useApi } from 'api';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ClientDetail: React.FC = () => {
  const params = useParams<{ agencyId: string; advisorId: string; clientId: string }>();
  const api = useApi();

  const client = useQuery<Client>({
    queryKey: ['agency', params.agencyId, 'advisor', params.advisorId, 'client', params.clientId],
    queryFn: async () => {
      const response = await api.get<{ data: Client }>(`/api/v1/clients/${params.clientId}/`);
      return response.data.data;
    },
    enabled: !!params.clientId,
  });

  return (
    <>
      {!client.data || client.isLoading ? (
        <Spin size='4xl' />
      ) : (
        <div className='w-full p-2 border border-gray-300 rounded-md'>
          <div className='flex flex-row'>
            <div>
              <Link
                to={`/agency/${params.agencyId}/advisor/${params.advisorId}`}
                className='mb-4 text-gray-500 hover:no-undeline hover:text-teal-700 text-base'
              >
                <FontAwesomeIcon icon={['fas', 'chevron-left']} className='ml-1 pt-1' color='#999' />{' '}
                {client.data.selectedAgent?.label || <Spin />}
              </Link>
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='grow'>
              <span className='text-teal-400 text-4xl'>{client.data.fullName}</span>{' '}
              <span className='text-gray-400'>Client</span>
            </div>
            <div>
              <Link to={`/agency/${params.agencyId}/advisor/${params.advisorId}/client/${params.clientId}/edit`}>
                <div className='btn-link'>Edit</div>
              </Link>
            </div>
          </div>
          <div>
            <div className='my-2 p-2 w-full bg-gray-100 border-2 border-gray-300 rounded-md shadow-md'>
              <div className='grid grid-cols-3'>
                <div className='flex flex-row'>
                  {client.data.fullName}{' '}
                  <div className='ml-2 text-gray-500 text-sm self-end'>
                    {client.data.gender === 2 ? 'Female' : 'Male'}, {client.data.currentAge}
                  </div>
                </div>
                {client.data.hasSpouse && <div className='flex flex-row'>
                  {client.data.spouseFullName}{' '}
                  <div className='ml-2 text-gray-500 text-sm self-end'>
                    {client.data.spouseGender === 2 ? 'Female' : 'Male'}, {client.data.spouseAge}
                  </div>
                </div>}
                <div></div>
                <div>
                  <span className='text-sm text-gray-500'>{client.data.email}</span>
                </div>
                <div>
                  <span className='text-sm text-gray-500'>{client.data.spouseEmail}</span>
                </div>
                <div>
                  <span className='text-sm text-gray-500'>{client.data.phone}</span>
                </div>
                <div>
                  <div className='text-gray-500 text-sm'>
                    {client.data.address} {client.data.city}
                  </div>
                </div>
                <div>
                  <div className=' text-gray-500 text-sm'>
                    {client.data.state} {client.data.postal}{' '}
                  </div>
                </div>
              </div>
              <div className='pt-4 text-large text-gray-700'>Asset / Income Sources</div>
              <div className='flex flex-row gap-x-8'>
                {client.data.assetIncomeSources &&
                  client.data.assetIncomeSources.map((incomeSource) => (
                    <div className='flex flex-col' key={incomeSource.id}>
                      <span className='text-sm text-gray-500'>{incomeSource.name}</span>
                      <span className='text-sm text-gray-500 text-right'>
                        {numeral(incomeSource.monthlyIncome).format('$0,0')}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <br />
          <div>
            <div>
              <BucketList agencyId={params.agencyId} advisorId={params.advisorId} clientId={params.clientId} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
